import { StatusBarForegroundColor } from 'apps/acp/packages/acp-config';

export interface StatusBarGlobal {
  /**
   * backgroundColorByHexString and styleDefault are
   * part of cordova-plugin-statusbar package to set background
   * color and font color respectively
   *
   * For more details https://github.com/apache/cordova-plugin-statusbar
   */
  backgroundColorByHexString(hexString: string): void;

  styleDefault(): void;
}

export interface StatusBarPlugin {
  /**
   * Sets the status bar color.
   */
  setStatusBarColor(
    hexCodeBackground: string,
    foregroundColor: StatusBarForegroundColor
  ): void;
}

export const createStatusBarPlugin = (
  statusBar?: StatusBarGlobal
): StatusBarPlugin => {
  if (!statusBar) {
    return {
      setStatusBarColor() {
        // noop
      }
    };
  }

  return {
    setStatusBarColor: (
      hexCodeBackground: string,
      foregroundColor: StatusBarForegroundColor
    ) => {
      statusBar.backgroundColorByHexString(hexCodeBackground);
      foregroundColor === 'dark' && statusBar.styleDefault();
    }
  };
};
